
import { Component, Vue } from 'vue-property-decorator'
import { createBetaUser } from '@/controllers/API'

@Component
export default class extends Vue {
  valid = false
  email = ''
  firstName = ''
  lastName = ''

  loading = false
  dialog = false
  error = false
  nameRules = [
    (v: string) => !!v || 'Name is required'
  ]

  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid'
  ]

  async validate () {
    this.loading = true
    // await new Promise((resolve) => setTimeout(resolve, 500))
    try {
      await createBetaUser(this.firstName, this.lastName, this.email)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.form.reset()

      this.loading = false
      this.dialog = true
    } catch (error) {
      console.log(error)
      this.loading = false
      this.error = true
    }
  }
}
