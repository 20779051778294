
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class extends Vue {
  valid = false
  email = ''
  name = ''

  nameRules = [
    (v: string) => !!v || 'Name is required'
  ]

  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid'
  ]

  validate () {
    console.log('validate')
  }
}
