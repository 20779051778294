import axios from 'axios'
const url = 'https://api.whatsyourlevel.app'
// const url = 'http://localhost:9000'

export async function createBetaUser (
  firstName: string,
  lastName: string,
  email: string) {
  await axios.post(url + '/create-beta-user', {
    firstName,
    lastName,
    email
  })
}
